<template>
  <div find-id>
    <router-view v-if="queries" />
  </div>
</template>

<script>
import { setNewRelic, setOneTrust, setSegment } from '@/plugins/tracking';
import { ggpassNullQueries, isExcludeGgpass } from '@/utils/baseUtil';
import { state } from '@shared/utils/storeUtils.mjs';
import { siteIds } from '@/constants/base/siteMap';
import { sleep } from '@shared/utils/commonUtils.mjs';

export default {
  name: 'FindId',
  components: { },
  data() {
    return {
      queries: null,
    };
  },
  computed: {
    ggpass: state('env', 'ggpass'),
    site: state('env', 'site'),
    theme: state('env', 'theme'),
    siteInfo: state('env', 'siteInfo'),
    findKey: state('query', 'findKey'),
    selectedLang: state('query', 'selectedLang'),
    baseLang: state('env', 'baseLang'),
    brand: state('env', 'brand'),
    isMBP() {
      return [siteIds.GGPUKE, siteIds.EVPUKE].includes(this.site);
    },

  },
  methods: {
    async initialize() {
      if (this.site === siteIds.GGVEGAS) {
        await this.replaceRouteName('NotProvide');
        return;
      }

      this.$changeLocale(this.baseLang);
      await sleep(60);
      await this.initRoute();

      if (!this.isMBP) await this.$services.captcha.reCaptchaInitialize();
      if(this.site === siteIds.GGPUKE) document.body.className = this.theme;

      setNewRelic();
      setSegment();
      setOneTrust(this.queries);
    },

    async initRoute() {
      if (this.findKey && this.$route.name !== 'Create') await this.replaceRouteName('FiEmailGuide');
      else if (this.$route.name !== 'FindId') await this.replaceRouteName('FindIdType');
    },
  },
  async mounted() {
    this.queries = ggpassNullQueries(this.$route.query);
    await this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[find-id] { .pt(40); .h(calc(var(--innerHeight) - 80px)); .flex(); .flex-dc(); //.h(calc(var(--innerHeight) - 200px));
  .desc { .m(12, 0, 40); .fs(18, 22); .regular(); .c(#7F7F7F); }
  .field-wrapper { .h(100%); .rel();
    .bottom { .w(100%); .t(54%); .abs(); .tc(); .c(#ccc);
      a { .rel(); .underline();
        [font-icon] { .c(@c-red); .fs(14); .abs(); .rt(-16, 50%); .t-yc();}
      }
    }
  }
  .contents {.flex(); .flex-dc(); .rel(); .h(100%); flex: 1; .pt(50);
    .field-holder {flex: 1; .flex(); .flex-dc();}
    .content {flex: 1; .flex(); .flex-dc();}
    .error-msg {.pl(2); .m(0); .c(@c-red); .fs(14); .pt(5);}
    .bottom { .w(100%); .tc(); .c(#ccc); .mb(80);
      .btn-holder {margin-bottom: 30px !important; .flex(); .justify-center(); .items-center(); .gap(5); .fs(16);
        .icon {.c(#C64040)}
      }
      [progress-button] {.max-w(100%);}
      a { .rel(); .underline();
        [font-icon] { .c(@c-red); .fs(16); .abs(); .rt(-16, 50%); .t-yc();}
      }
      .open-email {text-decoration-line: none;}
    }
    .or { .fs(16, 20); .c(#7F7F7F); .mv(20); .rel();
      span { .ph(12); .bgc(#171717); .rel(); .z(1);}
      em { .wh(100%, 1); .bgc(#333); .block(); .abs(); .lt(50%, 50%); .t-xyc(); .max-w(440); }
    }
  }
}
</style>